import React, { useRef } from 'react';
import CustomButton from '../buttons/CustomButton'
import CustomLabel from './CustomLabel'
import InputErrors from './InputErrors';

const fabricBaseUrl = process.env.REACT_APP_FABRIC_BASE_URL;

const CustomFileInput = (props) => {
  const fileInputRef = useRef(null);
  const { name, required, onChange, disabled, formData, errorSchema } = props;
  const id = props.idSchema?.$id ?? props.id;
  const label = props.schema?.title;
  const help = props.uiSchema['ui:help'];
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    
    const validity = validateFile(selectedFile);
    if (validity[0] !== true) {
      alert(validity[1]);
      return;
    }
    
    const file_key = Date.now() + '_' + selectedFile.name
    try {
      showSpinner();
      const credentialsResponse = await fetch(`${fabricBaseUrl}/presigned-s3-upload`, {
        method: 'POST',     
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          file_key: file_key
        })
      });
      if (!credentialsResponse.ok) {
        throw new Error('Getting presigned s3 url failed');
      }
      const s3Endpoint = await credentialsResponse.text()
      
      const s3Response = await fetch(s3Endpoint, {
        method: 'PUT',
        body: selectedFile,
        headers: {
          'Content-Type': selectedFile.type
        }
      });
  
      if (!s3Response.ok) {
        throw new Error('s3 Upload failed');
      }

      const formData = {
        name: selectedFile.name,
        key: file_key,
        is_file: true
      };
      onChange(formData);
    } catch (err) {
      alert('Error uploading file. Please try again.');
    }
    finally {
      hideSpinner();
    }
  };

  const validateFile = (file) => {
    const maxSizeBytes = 100 * 1024 * 1024; // 100mb in bytes
    if (file.size > maxSizeBytes) {
      return [false, 'File too large.'];
    }

    const fileName = file.name.toLowerCase();
    const fileExtension = '.' + fileName.split('.').pop();
    if (fileExtension === '.exe') {
      return [false, 'File not allowed.'];
    }

    return [true, ''];
  }
  
  const handleButtonClick = () => {
    fileInputRef.current.click(); 
  };
    
  const showSpinner = () => {
    const el = document.getElementById('fileLoadingSpinner'+id);
    el.classList.remove('hidden');
  }

  const hideSpinner = () => {
    const el = document.getElementById('fileLoadingSpinner'+id);
    el.classList.add('hidden');
  }

  return (
    <div id={id} name={name}>
        <CustomLabel htmlFor={id} tooltip={help}>
          {label}
        </CustomLabel >
        <CustomButton
          type="button"
          className="text-xs mt-2"
          disabled={disabled}
          onClick={handleButtonClick}>
          Upload File
        </CustomButton>
        {
          !formData?.key && 
          <span class="ml-2">No file chosen</span>
        }
        {
          formData?.key && 
          <a class="text-blue-600 inline hover:underline ml-2" href={`${fabricBaseUrl}/download/${formData.key}`}>{formData.name ?? formData.key}</a>
        }
        <input
          type="file" 
          className="hidden"
          ref={fileInputRef}
          required={required}
          disabled={disabled}
          onChange={handleFileChange}
        />

      <div id={'fileLoadingSpinner'+id} className="inline hidden ml-2 mt-2">
        <svg class="inline" width="20" height="20" viewBox="0 0 50 50">
          <circle cx="25" cy="25" r="20" stroke="black" strokeWidth="4" fill="none" strokeDasharray="31.4" strokeLinecap="round">
            <animateTransform attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="1s" repeatCount="indefinite" />
          </circle>
        </svg>
      </div>
      
      {errorSchema?.key?.__errors && errorSchema?.key?.__errors.length > 0 && <InputErrors errorMessages={errorSchema?.key?.__errors} />}

    </div>
  );
};

export default CustomFileInput;
